const settings = {
  pageSize: 25,
  querySize: 1000,
  cacheTimeout: 300000,
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8004/graphql/',
  apiWsUrl: process.env.REACT_APP_API_WS_URL || 'ws://localhost:8004/graphql/',
  pusherKey: process.env.REACT_PUSHER_KEY || '71369accacf9272ebe6c',
  pusherCluster: process.env.REACT_PUSHER_CLUSTER || 'us3',
  googleOAuthClientId:
    '949777386714-glaf5pg9i3htagj0vnallclvhe0e8uhs.apps.googleusercontent.com',
  googleOAuthRedirect: 'http://localhost:3000/home/',
  screenSizes: {
    lg: 992,
    md: 600,
    sm: 400,
  },
  colors: {
    primary: '#e7282f',
    secondary: '#093151',
    warning: '#E8BD4E',
    warningText: '#b98b18',
    ghost: '#eef1f2',
    gray: '#E5E5E5',
    error: '#ff4d4f',
    red: '#f5222d',
    yellow: '#fadb14',
    green: '#73d13d',
    borderGray: '#d9d9d9',
    blue: '#336195',
    textGray: '#929292',
  },
};

export default settings;
